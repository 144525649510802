$snakeskin-dark-grey: #7A7671;
$snakeskin-medium-grey: #B5AFA7;
$snakeskin-light-grey: #D7D2C9;
$snakeskin-dark-beige: #CCB588;
$snakeskin-light-beige: #E3CCA8;

$snakeskin-ultradark-beige: darken($snakeskin-dark-beige, 30%);
$snakeskin-ultradark-grey: darken($snakeskin-dark-grey, 30%);

$apress-yellow: #EEC338;
