@import "./colours";
html {
  position: relative;
  min-height: 100%;
  min-height: 100vh;
  
}
.App>.container>article,.App>.container>div {
  margin-bottom: 170px !important; /* Margin bottom by footer height */
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 150px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: $snakeskin-light-grey;
  a {
    color: black;
    text-decoration: underline;
  }
  .disclaimer {
    line-height: 0.9em;
    font-size: 8pt;
  }
}

blockquote.twitter-tweet {
  margin-bottom: 1.5em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid gray;
}

article a {
  color: $snakeskin-ultradark-beige;
  text-decoration: underline;
}

article kbd {
  color: black;
  background: $snakeskin-light-grey;
  white-space: nowrap;
}

article .tableflow  {
  overflow-x: auto;
  width: 100% !important;
}

article table td {
  vertical-align: top;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid $snakeskin-ultradark-grey;
  pre {
    font-size: 77.5%;
  }
}

#root {
  overflow: hidden;
}
.App {

}

pre {
  .red {
    color: red;
  }
  .green {
    color: green; 
  }
}

.video_wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
  

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: beige;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

img.big
{
  width: 100%;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

pre+em {
  display: block; 
}

.article-intro {
  margin-bottom: 5ex;
  cite {
    color: $snakeskin-dark-grey;
  }
}

.article-list {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  a {
    padding: 0;
    font-size: 14pt;
    color: black;
    &:hover {
      color: $snakeskin-ultradark-beige;
    }
    text-decoration: underline;
  }
  li {
    padding: 2ex;
    border: 2px solid $snakeskin-dark-grey;
    flex-basis: 40%;
    min-width: 300px;
    flex-grow: 1;
    margin: 2ex;
  }
  span {
    float: right;
  }
}

a.brandcolor svg[data-icon="github"] {
  color: black;
}

a.brandcolor svg[data-icon="twitter"] {
  color: #00aced;
  &:hover {
    color: #0084b4;
  }
}

/* ----- REAL ------ */

.book-page {
  .page-select-element {
    display: flex;
    flex-direction: row;
    .page-select-input {
      display: block;
      margin: auto;
      margin-top: 10px;
      input {
        width: 3em;
      }
    }
  }
  button {
    height: 100%;
    display: block;
    background: beige;
  }
  
  img {
    background: whitesmoke;
    max-width: 300px;
    box-shadow: 2px 2px #eee;
    border: 1px solid #999;
  }
  
  .page-navigate {
    background: none;
    border: none;
    font-size: 16px;
    padding: 5px;
    --fa-primary-color: #{$snakeskin-ultradark-beige};
    --fa-secondary-color: #{$snakeskin-light-beige};
    --fa-secondary-opacity: 1.0;
    &:disabled {
      --fa-primary-color: #{$snakeskin-dark-grey};
      --fa-secondary-color: #{$snakeskin-light-grey};
      background: none;
    }
  }
}

nav {
  margin-bottom: 10px;
}

.page-metadata {
  text-align: left;
}


div.alert {
  a {
    color: inherit;
    text-decoration: underline;
    font-weight: bold;
  }
}

// Bootstrap overrides

.custom-control-input:checked~.custom-control-label::before {
  border-color: $snakeskin-light-beige !important;
  background-color: $snakeskin-light-beige !important;
}
.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: $snakeskin-ultradark-beige !important;
}